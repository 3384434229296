import React, { createContext, useState } from 'react'
import { AppLocale } from '@hrk/types'
import { useLocale } from '../hooks/useLocale'

export interface ILayoutContext {
  selectedLocale: AppLocale
  setSelectedLocale: (newLocale: AppLocale) => void
}

const defaultContext: ILayoutContext = {
  selectedLocale: 'de',
  setSelectedLocale: () => {
    return
  },
}
export const LayoutContext = createContext<ILayoutContext>(defaultContext)

export const LayoutContextProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { pageLocale } = useLocale()
  const [selectedLocale, setSelectedLocale] = useState<AppLocale>(pageLocale)

  return (
    <LayoutContext.Provider value={{ selectedLocale, setSelectedLocale }}>
      <>{props.children}</>
    </LayoutContext.Provider>
  )
}
